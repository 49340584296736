import React, { useState } from "react"
import { useForm, ErrorMessage } from "react-hook-form"
import { graphql, useStaticQuery } from "gatsby"
import classNames from "classnames"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faExclamationTriangle } from "../../../fontAwesome/pro-duotone-svg-icons"
function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

const ContactUsForm = () => {
  const { register, handleSubmit, formState, errors, reset } = useForm({
    mode: "onBlur",
  })

  const data = useStaticQuery(graphql`
    {
      sanityContact {
        contactUsForm {
          companyDepartment
          companyName
          companyNameRequired
          email
          emailPattern
          emailRequired
          firstName
          firstNameRequired
          formTitle
          lastName
          phoneNumber
          lastNameRequired
          radioButtons
          buttonText
          companyDepartmentPlaceholder
          companyNamePlaceholder
          emailPlaceholder
          firstNamePlaceholder
          lastNamePlaceholder
          message
          messagePlaceholder
          phoneNumberPlaceholder
          successMessage
        }
      }
    }
  `)
  const formData = data.sanityContact.contactUsForm

  const [state, setState] = React.useState({})
  const [feedbackState, setFeedbackState] = useState(null)
  const [errorState, setErrorState] = useState(null)

  const errorsFirstName = classNames("inputStyle", {
    inputStyleValid: !errors.firstName,
    inputStyleError: errors.firstName,
  })
  const errorsLastName = classNames("inputStyle", {
    inputStyleValid: !errors.lastName,
    inputStyleError: errors.lastName,
  })
  const errorsEmail = classNames("inputStyle", {
    inputStyleValid: !errors.email,
    inputStyleError: errors.email,
  })
  const errorsPhoneNumbers = classNames("inputStyle", {
    inputStyleValid: !errors.phoneNumber,
    inputStyleError: errors.phoneNumber,
  })

  const handleChange = e => {
    setState({ ...state, [e.target.name]: e.target.value })
  }

  const onSubmit = (data, e) => {
    e.preventDefault()
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": "contactGudcForm",
        ...state,
      }),
    })
      .then(response => {
        setFeedbackState(true)
        reset()
      })
      .catch(error => {
        setErrorState(false)
      })
  }
  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      name="contactGudcForm"
      method="post"
      data-netlify="true"
      data-netlify-honeypot="bot-field"
      autocomplete="on"
    >
      <div className="field " className={errorsFirstName}>
        <label for="field-fName" className="labelStyle">
          {formData.firstName}
        </label>
        <div id="error-required-field-fName">
          <ErrorMessage
            errors={errors}
            name="firstName"
            message={formData.firstNameRequired}
          >
            {({ message }) => (
              <span className="errorMessage">
                <FontAwesomeIcon
                  icon={faExclamationTriangle}
                  className="formErrorIcon"
                  role="pressentation"
                />
                {message}
              </span>
            )}
          </ErrorMessage>
        </div>
        <div className="control">
          <input
            className={errorsFirstName}
            id="field-fName"
            type="text"
            name="firstName"
            placeholder={formData.firstNamePlaceholder}
            onChange={handleChange}
            ref={register({ required: true, maxLength: 75 })}
            aria-describedby="error-required-field-fName"
            autocomplete="given-name"
            required
          />
        </div>
      </div>

      <div className="field" className={errorsLastName}>
        <label for="field-lName" className="labelStyle">
          {formData.lastName}
        </label>
        <div id="error-required-field-lName">
          <ErrorMessage
            errors={errors}
            name="lastName"
            message={formData.lastNameRequired}
          >
            {({ message }) => (
              <span className="errorMessage">
                <FontAwesomeIcon
                  icon={faExclamationTriangle}
                  className="formErrorIcon"
                  role="pressentation"
                />
                {message}
              </span>
            )}
          </ErrorMessage>
        </div>
        <div className="control">
          <input
            className={errorsLastName}
            type="text"
            id="field-lName"
            placeholder={formData.lastNamePlaceholder}
            name="lastName"
            autocomplete="family-name"
            required
            aria-describedby="error-required-field-lName"
            onChange={handleChange}
            ref={register({ required: true, maxLength: 8 })}
          />
        </div>
      </div>

      <div className="field">
        <label for="field-business" className="labelStyle">
          {formData.companyName}
        </label>

        <div className="control">
          <input
            id="field-business"
            className="inputStyle"
            type="text"
            name="business"
            placeholder={formData.companyNamePlaceholder}
            onChange={handleChange}
            ref={register()}
          />
        </div>
        <div className="field" />

        <div className="field" className={errorsEmail}>
          <label for="email" className="labelStyle">
            {formData.email}
          </label>
          <span
            id="error-required-field-email"
            className="errorMessage"
            style={{
              display:
                errors.email && errors.email.type === "required"
                  ? "block"
                  : "none",
            }}
          >
            <FontAwesomeIcon
              icon={faExclamationTriangle}
              className="formErrorIcon"
              role="pressentation"
            />
            {formData.emailRequired}
          </span>
          <span
            id="error-required-field-pattern"
            className="errorMessage"
            style={{
              display:
                errors.email && errors.email.type === "pattern"
                  ? "block"
                  : "none",
            }}
          >
            <FontAwesomeIcon
              icon={faExclamationTriangle}
              className="formErrorIcon"
              role="pressentation"
            />
            {formData.emailPattern}
          </span>
          <span
            id="error-required-field-maxLength"
            className="errorMessage"
            style={{
              display:
                errors.email && errors.email.type === "maxLength"
                  ? "block"
                  : "none",
            }}
          >
            <FontAwesomeIcon
              icon={faExclamationTriangle}
              className="formErrorIcon"
              role="pressentation"
            />
            Max length of 100 characters exceeded
          </span>

          <input
            type="email"
            id="email"
            name="email"
            className={errorsEmail}
            aria-invalid={errors.email ? "true" : "false"}
            placeholder={formData.emailPlaceholder}
            aria-describedby="error-required-field-email error-required-field-maxLength error-required-field-pattern"
            onChange={handleChange}
            autocomplete="email"
            ref={register({
              required: true,
              pattern: /^[a-zA-Z0-9\-.]+@(?:[a-zA-Z0-9]+\.)+[A-Za-z]+$/,
              maxLength: 100,
            })}
          />
        </div>
      </div>
      <div className="field" className={errorsPhoneNumbers}>
        <label for="tel" className="labelStyle">
          {formData.phoneNumber}
        </label>

        <span
          id="error-required-field-tel"
          className="errorMessage"
          style={{
            display:
              errors.phoneNumber && errors.phoneNumber.type === "maxLength"
                ? "block"
                : "none",
          }}
        >
          <FontAwesomeIcon
            icon={faExclamationTriangle}
            className="formErrorIcon"
            role="pressentation"
          />
          Phone number cannot exceed 25 letters and numbers
        </span>

        <div className="control">
          <input
            className={errorsPhoneNumbers}
            type="tel"
            id="tel"
            describedby="error-required-field-tel"
            placeholder="Phone number"
            name="phoneNumber"
            autocomplete="tel"
            onChange={handleChange}
            ref={register({ maxLength: 25 })}
          />
        </div>
      </div>

      <div className="field">
        <label for="companyDepartment" className="labelStyle">
          {formData.companyDepartment}
        </label>

        <div className="control">
          <input
            className="inputStyle"
            type="text"
            id="companyDepartment"
            name="companyDepartment"
            placeholder={formData.companyDepartmentPlaceholder}
            onChange={handleChange}
            ref={register()}
          />
        </div>
      </div>

      <fieldset>
        <legend className="radioLabelStyle">Inquery type</legend>

        {formData.radioButtons.map((node, index) => (
          <>
            <p>
              <label key={index} className="radioStyle">
                <input
                  type="radio"
                  name="inqueryType"
                  ref={register()}
                  value={node}
                  onChange={handleChange}
                  key={(node, "radio")}
                />
                {node}
              </label>
            </p>
          </>
        ))}
      </fieldset>
      <div className="field">
        <label for="message" className="labelStyle">
          {formData.message}
        </label>

        <div className="control">
          <textarea
            id="message"
            className="inputStyle"
            type="text"
            placeholder={formData.messagePlaceholder}
            name="message"
            onChange={handleChange}
            ref={register()}
            rows="7"
          />
        </div>
      </div>
      <div className="field is-grouped">
        <div className="control">
          {feedbackState && (
            <div className="notification is-link">
              {formData.successMessage}
            </div>
          )}
          {errorState && (
            <div className="notification is-warning">
              Something went wrong on our, sorry about that! Please contact us
              manually
            </div>
          )}

          <button className="button is-link" type="submit">
            Submit form
          </button>
        </div>
      </div>
    </form>
  )
}
export default ContactUsForm
