import React from "react"
import Layout from "../components/Layout/Layout"
import SEO from "../components/SEO/SEO"
import { Container, Section, Title } from "bloomer"
import Form from "../components/Forms/contactUsForm/contactUsForm"
import "../styles/contactUs.scss"

const BlockContent = require("@sanity/block-content-to-react")

const ContactUs = ({ data }) => (
  <Layout>
    <SEO title="Contact Us" />
    <main>
      <Section className="formSite page-main__section contact-page-main__section">
        <Container>
          <Title className="is-1 title introTitle" id="main">
            {data.sanityContact.title}
          </Title>
          <BlockContent blocks={data.sanityContact._rawBody} />
          <Form />
        </Container>
      </Section>
    </main>
  </Layout>
)
export const query = graphql`
  {
    sanityContact {
      _rawBody
      title
    }
  }
`
export default ContactUs
